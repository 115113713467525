import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import router from './router'
import 'element-ui/lib/theme-chalk/index.css';
// 分页组件
import Pagination from "@/components/Pagination";
//百度富文本组件
import VueUeditorWrap from 'vue-ueditor-wrap'
import tinymce from 'tinymce';



// 全局组件挂载
Vue.component('Pagination', Pagination)
Vue.component('vue-ueditor-wrap', VueUeditorWrap)
Vue.prototype.$tinymce = tinymce;

Vue.use(ElementUI);

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
