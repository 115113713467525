<template>
  <div id="app">
    <el-container>
      <el-header height="100">
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-form-item label="用户账号">
            <el-input v-model="formInline.account" placeholder="用户账号"></el-input>
          </el-form-item>
          <el-form-item label="订单号">
            <el-input v-model="formInline.oid" placeholder="订单号"></el-input>
          </el-form-item>
          <el-form-item label="商品名称">
            <el-input v-model="formInline.name" placeholder="商品名称"></el-input>
          </el-form-item>
          <el-form-item label="用户姓名">
            <el-input v-model="formInline.siteName" placeholder="用户姓名"></el-input>
          </el-form-item>
          <el-form-item label="用户电话">
            <el-input v-model="formInline.siteMobile" placeholder="用户电话"></el-input>
          </el-form-item>
          <el-form-item label="用户地址">
            <el-input v-model="formInline.siteAddress" placeholder="用户地址"></el-input>
          </el-form-item>
          <el-form-item label="物流单号">
            <el-input v-model="formInline.expressNo" placeholder="物流单号"></el-input>
          </el-form-item>
          <el-form-item label="物流公司">
            <el-input v-model="formInline.expressName" placeholder="物流公司"></el-input>
          </el-form-item>
          <el-form-item label="状态">
            <el-select v-model="formInline.status" placeholder="状态">
              <el-option label="待发货" value="1"></el-option>
              <el-option label="待收货" value="2"></el-option>
              <el-option label="已完成" value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">搜索</el-button>
            <el-button type="warning" @click="reset">重置</el-button>
          </el-form-item>
        </el-form>
      </el-header>
      <el-main>
        <el-table
          :data="tableData"
          :loading="loading"
          border
          style="width: 100%"
        >
          <el-table-column prop="account" label="用户账号" width="100">
          </el-table-column>
          <el-table-column prop="oid" label="订单号" width="300">
          </el-table-column>
          <el-table-column prop="name" label="商品名称"> </el-table-column>
          <el-table-column prop="picture" label="商品图片">
            <template slot-scope="scope">
              <el-image
                :src="
                  'https://shop2.dlqineng.com' +
                  JSON.parse(scope.row.picture)[0]
                "
                :preview-src-list="[JSON.parse(scope.row.picture)[0]]"
                style="width: 40px; height: 40px"
              />
            </template>
          </el-table-column>
          <el-table-column prop="price" label="商品价格" width="80">
          </el-table-column>
          <el-table-column prop="integral" label="商品积分" width="80">
          </el-table-column>
          <el-table-column prop="number" label="商品数量" width="80">
          </el-table-column>
          <el-table-column prop="siteName" label="用户姓名"> </el-table-column>
          <el-table-column prop="siteMobile" label="用户电话">
          </el-table-column>
          <el-table-column prop="siteAddress" label="用户地址">
          </el-table-column>
          <el-table-column prop="expressNo" label="物流单号"> </el-table-column>
          <el-table-column prop="expressName" label="物流公司">
          </el-table-column>
          <el-table-column prop="status" label="状态">
            <template slot-scope="scope">
              <el-tag
                v-for="tag in dictData"
                v-if="scope.row.status == tag.value"
                :key="tag.value"
                :type="tag.type"
              >
                {{ tag.label }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="操作"
            class-name="small-padding fixed-width"
          >
            <template slot-scope="scope">
              <el-button
                v-if="scope.row.status == 1"
                type="primary"
                size="mini"
                @click="handleUpdate(scope.row)"
                >发货</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <div>
          <pagination
            v-show="total > 0"
            :total="total"
            :page.sync="formInline.pageNum"
            :limit.sync="formInline.pageSize"
            @pagination="getList"
            style="float: right"
          />
        </div>

        <el-dialog
          title="订单发货"
          :visible.sync="addShow"
          width="60%"
          :before-close="handleClose"
        >
          <el-form ref="form" :model="form" label-width="90px" :rules="rules">
            <el-form-item prop="expressNo" label="物流单号">
              <el-input v-model="form.expressNo" type="text"></el-input>
            </el-form-item>
            <el-form-item prop="expressName" label="物流公司">
              <el-input v-model="form.expressName"></el-input>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="addShow = false">取 消</el-button>
            <el-button type="primary" @click="SubmitForm">确 定</el-button>
          </span>
        </el-dialog>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "App",
  data() {
    return {
      dictData: [
        { value: "1", label: "待发货", type: "danger" },
        { value: "2", label: "待收货", type: "warning" },
        { value: "3", label: "完成", type: "success" },
        { value: "4", label: "已取消", type: "info" },
      ],
      tableData: [],
      formInline: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 10,
      loading: false,
      addShow: false,
      form: {},
      imageUrl: "",
      exform: {},
      exShow: false,
      rules: {
        expressNo: [
          { required: true, message: "请输入物流单号", trigger: "blur" },
        ],
        expressName: [
          { required: true, message: "请输入物流公司", trigger: "blur" },
        ],
      },
      url: [],
      galleryFileList: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.formInline.storeId = this.$route.params.userId;
      const formData = new FormData(); // 直接使用 FormData 构造函数
      Object.keys(this.formInline).forEach((key) => {
        formData.append(key, this.formInline[key]);
      });
      axios({
        method: "post",
        url: "https://shop2.dlqineng.com/develop/productbill/listPc",
        data: formData,
      })
        .then((res) => {
          this.tableData = res.data.rows;
          this.total = res.data.total;
          this.loading = false;
        })
        .catch((response) => {
          this.loading = false;
        });
    },
    handleUpdate(row) {
      this.form.id = row.id;
      this.addShow = true;
    },
    onSubmit() {
      this.getList();
    },
    reset() {
      this.formInline = {
        pageNum: 1,
        pageSize: 10,
      };
      this.getList();
    },
    handleCreate() {
      this.form = {
        picture: [],
      };
      this.businessLicense = "";
      this.barCode = "";
      this.inspectionReport = "";
      this.trademarkCertificate = "";
      this.addShow = true;
    },
    SubmitForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          axios({
            method: "post",
            url: "https://shop2.dlqineng.com/develop/productbill/editPc",
            data: this.form,
          }).then((res) => {
            this.addShow = false;
            this.getList();
          });
        }
      });
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    callbackChangeEditor(e) {
      this.form.info = e;
    },
    // 保存富文本内容-保存字符串
    add() {
      const params = `this.tiny?.value`;
    },
    edit() {
      // 接口回显保存html
      // this.tiny.value = res.data|| ''
    },
  },
};
</script>
<style >
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px !important;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.edit_container .quill-editor .ql-container .ql-editor {
  height: 260px;
}
.tox-tinymce-aux {
  z-index: 999900000000 !important;
}
.tox-statusbar {
  width: 1030px !important;
}
.tox-editor-container {
  width: 1030px !important;
}
.tox-tinymce {
  width: 1030px !important;
}
</style>