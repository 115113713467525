import Vue from 'vue';
import Router from 'vue-router';
import login from '@/components/login.vue';
import product from '@/components/product.vue';
import order from '@/components/order.vue';
Vue.use(Router);
const routes = [
  { path: '/',name:"login", component: login },
  { path: '/product/:userId/:token',name:'product', component: product },
  { path: '/order/:userId/:token',name:'order', component: order }
]

const router = new Router({ routes });

router.beforeEach((to, from, next) => {
  // 正确地从from对象中获取上一个路由的name
  const fromRouteName = from.name;

  // 使用history.state来判断是否为页面刷新
  const isRefresh = history.state && Object.keys(history.state).length > 0;

  if (to.name === 'product' && (!fromRouteName || fromRouteName !== 'login')) {
    // 如果尝试访问product页面但不是从login页面来的
    if (!isRefresh) {
      // 并且不是页面刷新，则重定向到login
      next({ name: 'login' });
    } else {
      // 如果是页面刷新，则允许继续访问
      next();
    }
  } else {
    // 记录当前路由名称到localStorage，以便后续检查
    localStorage.setItem('lastRoute', fromRouteName);
    next();
  }

  if (to.name === 'order' && (!fromRouteName || fromRouteName !== 'product')) {
    // 如果尝试访问product页面但不是从login页面来的
    if (!isRefresh) {
      // 并且不是页面刷新，则重定向到login
      next({ name: 'login' });
    } else {
      // 如果是页面刷新，则允许继续访问
      next();
    }
  } else {
    // 记录当前路由名称到localStorage，以便后续检查
    localStorage.setItem('lastRoute', fromRouteName);
    next();
  }
});

export default router;