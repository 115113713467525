<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: 'App',
  components: {}
}
</script>
<style scoped>
#app {
  padding: 0;
  margin: -8px;
  height: 100vh;
}
</style>
