<template>
  <div id="app">
    <el-container>
      <el-header height="100">
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-form-item label="名称">
            <el-input v-model="formInline.name" placeholder="名称"></el-input>
          </el-form-item>
          <el-form-item label="状态">
            <el-select v-model="formInline.status" placeholder="状态">
              <el-option label="已下架" value="0"></el-option>
              <el-option label="已上架" value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">搜索</el-button>
            <el-button type="warning" @click="reset">重置</el-button>
          </el-form-item>
        </el-form>
        <el-row :gutter="10" class="mb8">
          <el-col :span="1.5">
            <el-button
              type="primary"
              plain
              icon="el-icon-plus"
              size="mini"
              @click="handleCreate"
              >新增</el-button
            >
            <el-button
              type="primary"
              plain
              icon="el-icon-shopping-cart-full"
              size="mini"
              @click="handleOrder"
              >订单发货</el-button
            >
          </el-col>
        </el-row>
      </el-header>
      <el-main>
        <el-table
          :data="tableData"
          :loading="loading"
          border
          style="width: 100%"
        >
          <el-table-column prop="type" label="分类" width="180">
          </el-table-column>
          <el-table-column prop="name" label="名称" width="180">
          </el-table-column>
          <el-table-column prop="picture" label="图片">
            <template slot-scope="scope">
              <el-image
                :src="
                  'https://shop2.dlqineng.com' +
                  JSON.parse(scope.row.picture)[0]
                "
                :preview-src-list="[JSON.parse(scope.row.picture)[0]]"
                style="width: 40px; height: 40px"
              />
            </template>
          </el-table-column>
          <el-table-column prop="originalPrice" label="原价"> </el-table-column>
          <el-table-column prop="price" label="价格"> </el-table-column>
          <el-table-column prop="integral" label="积分"> </el-table-column>
          <el-table-column prop="inventory" label="库存"> </el-table-column>
          <el-table-column prop="ap" label="活跃度(%)"> </el-table-column>
          <el-table-column prop="number" label="抽奖次数"> </el-table-column>
          <el-table-column prop="sort" label="排序"> </el-table-column>
          <el-table-column prop="status" label="状态">
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.status"
                :active-value="1"
                :inactive-value="0"
                @change="updateUserStatus(scope.row)"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="examine" label="审核状态">
            <template slot-scope="scope">
              <el-tag
                v-for="tag in ExamineTypeList"
                v-if="scope.row.examine == tag.dictValue"
                :key="tag.dictValue"
                :type="tag.listClass"
              >
                {{ tag.dictLabel }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="操作"
            width="250"
            class-name="small-padding fixed-width"
          >
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="mini"
                @click="handleUpdate(scope.row)"
                >编辑</el-button
              >
              <el-button
                type="danger"
                size="mini"
                @click="handleDelete(scope.row)"
                >删除</el-button
              >
              <el-button
                type="warning"
                size="mini"
                v-if="scope.row.examine == 2"
                @click="handleSee(scope.row)"
                >原因</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <div>
          <pagination
            v-show="total > 0"
            :total="total"
            :page.sync="formInline.pageNum"
            :limit.sync="formInline.pageSize"
            @pagination="getList"
            style="float: right"
          />
        </div>

        <el-dialog
          :title="title"
          :visible.sync="addShow"
          width="60%"
          :before-close="handleClose"
        >
          <el-form ref="form" :model="form" label-width="90px" :rules="rules">
            <el-form-item prop="type" label="分类">
              <el-select v-model="form.type" placeholder="请选择分类">
                <el-option
                  v-for="item in productTypeList"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                  :key="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="name" label="名称">
              <el-input v-model="form.name" type="text"></el-input>
            </el-form-item>
            <el-form-item
              prop="picture"
              label="图片"
              v-if="title == '新增商品'"
            >
              <!-- <el-upload
                v-model="form.picture"
                class="avatar-uploader"
                action="https://shop2.dlqineng.com/common/uploadPc"
                :show-file-list="true"
                 list-type="picture-card"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload> -->
              <el-upload
                action="https://shop2.dlqineng.com/common/uploadPc"
                :limit="5"
                :on-exceed="uploadOverrun"
                :on-success="handleAvatarSuccess"
                :on-remove="handleRemove"
                multiple
                accept=".jpg,.jpeg,.png,.gif,.mp4"
                list-type="picture-card"
              >
                <i class="el-icon-plus" />
                <!-- <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i> -->
              </el-upload>
            </el-form-item>

            <el-form-item label="图片" prop="picture" v-else>
              <el-upload
                action="https://shop2.dlqineng.com/common/uploadPc"
                :limit="5"
                :file-list="galleryFileList"
                :on-exceed="uploadOverrun"
                :on-success="handleAvatarSuccess"
                :on-remove="handleRemove"
                multiple
                accept=".jpg,.jpeg,.png,.gif,video/mp4"
                list-type="picture-card"
              >
                <i class="el-icon-plus" />
              </el-upload>
            </el-form-item>
            <Tinymce :height="200" v-model="form.info"></Tinymce>
            <el-form-item prop="businessLicense" label="营业执照">
              <el-upload
                v-model="form.businessLicense"
                class="avatar-uploader"
                action="https://shop2.dlqineng.com/common/uploadPc"
                :on-success="handleAvatarBusinessLicenseSuccess"
                :show-file-list="false"
                :before-upload="beforeAvatarUpload"
              >
                <img
                  v-if="businessLicense"
                  :src="businessLicense"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
            <el-form-item prop="barCode" label="条形码">
              <el-upload
                v-model="form.barCode"
                class="avatar-uploader"
                action="https://shop2.dlqineng.com/common/uploadPc"
                :on-success="handleAvatarBarCodeSuccess"
                :show-file-list="false"
                :before-upload="beforeAvatarUpload"
              >
                <img v-if="barCode" :src="barCode" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
            <el-form-item prop="inspectionReport" label="检验报告">
              <el-upload
                v-model="form.inspectionReport"
                class="avatar-uploader"
                action="https://shop2.dlqineng.com/common/uploadPc"
                :on-success="handleAvatarInspectionReportSuccess"
                :show-file-list="false"
                :before-upload="beforeAvatarUpload"
              >
                <img
                  v-if="inspectionReport"
                  :src="inspectionReport"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
            <el-form-item prop="trademarkCertificate" label="商标证书">
              <el-upload
                v-model="form.trademarkCertificate"
                class="avatar-uploader"
                action="https://shop2.dlqineng.com/common/uploadPc"
                :on-success="handleAvatarTrademarkCertificateSuccess"
                :show-file-list="false"
                :before-upload="beforeAvatarUpload"
              >
                <img
                  v-if="trademarkCertificate"
                  :src="trademarkCertificate"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
            <el-form-item prop="originalPrice" label="原价">
              <el-input v-model="form.originalPrice"></el-input>
            </el-form-item>
            <el-form-item prop="price" label="价格">
              <el-input v-model="form.price"></el-input>
            </el-form-item>
            <el-form-item prop="integral" label="积分">
              <el-input v-model="form.integral"></el-input>
            </el-form-item>
            <el-form-item prop="inventory" label="库存">
              <el-input v-model="form.inventory"></el-input>
            </el-form-item>
            <el-form-item prop="sort" label="排序">
              <el-input v-model="form.sort" type="number"></el-input>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="addShow = false">取 消</el-button>
            <el-button type="primary" @click="SubmitForm">确 定</el-button>
          </span>
        </el-dialog>

        <el-dialog
          title="驳回理由"
          :visible.sync="exShow"
          width="30%"
          :before-close="handleClose"
        >
          <el-form ref="form" :model="exform" label-width="80px">
            <el-form-item label="驳回理由">
              <el-input v-model="exform.rejectInfo"></el-input>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="exShow = false">关 闭</el-button>
          </span>
        </el-dialog>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import axios from "axios";
import Tinymce from "./Tinymce";

export default {
  name: "App",
  components: {
    Tinymce,
  },
  data() {
    return {
      tableData: [],
      formInline: {
        pageNum: 1,
        pageSize: 10,
      },
      isDisabled: false,
      // 富文本强制每次打开刷新
      eidtFlag: 0,
      total: 10,
      loading: false,
      addShow: false,
      title: "",
      form: {
        picture: [],
      },
      businessLicense: "",
      businessLicenseList: [],
      barCode: "",
      barCodeList: [],
      inspectionReport: "",
      inspectionReportList: [],
      trademarkCertificate: "",
      trademarkCertificateList: [],
      productTypeList: [],
      ExamineTypeList: [],
      imageUrl: "",
      UeditorConfig: {
        // 编辑器不自动被内容撑高
        autoHeightEnabled: true,
        // 初始容器高度
        initialFrameHeight: 500,
        // 初始容器宽度
        initialFrameWidth: "100%",
        // UEditor 资源文件的存放路径，
        UEDITOR_HOME_URL: "/UEditor/",
      },
      content: "<p>这里是初始内容</p>",
      exform: {},
      exShow: false,
      rules: {
        type: [{ required: true, message: "请选择分类", trigger: "change" }],
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        picture: [{ required: true, message: "请上传图片", trigger: "blur" }],
        info: [{ required: true, message: "请输入内容", trigger: "blur" }],
        price: [{ required: true, message: "请输入价格", trigger: "blur" }],
        originalPrice: [{ required: true, message: "请输入原价", trigger: "blur" }],
        integral: [{ required: true, message: "请输入积分", trigger: "blur" }],
        inventory: [{ required: true, message: "请输入库存", trigger: "blur" }],
        sort: [{ required: true, message: "请输入排序", trigger: "blur" }],
        businessLicense: [
          { required: true, message: "请上传营业执照", trigger: "blur" },
        ],
        barCode: [{ required: true, message: "请上传条形码", trigger: "blur" }],
        inspectionReport: [
          { required: true, message: "请上传检验报告", trigger: "blur" },
        ],
        trademarkCertificate: [
          { required: true, message: "请上传商标证书", trigger: "blur" },
        ],
        isCorporation: [
          {
            required: true,
            message: "请选择是否是公司产品",
            trigger: "change",
          },
        ],
        number: [
          { required: true, message: "请输入抽奖次数", trigger: "blur" },
        ],
        ap: [{ required: true, message: "请输入活跃度", trigger: "blur" }],
      },
      url: [],
      galleryFileList: [],
    };
  },
  created() {
    this.getList();
    this.getProductType();
    this.getExamineType();
  },
  methods: {
    getList() {
      this.formInline.createBy = this.$route.params.userId;
      const formData = new FormData(); // 直接使用 FormData 构造函数
      Object.keys(this.formInline).forEach((key) => {
        formData.append(key, this.formInline[key]);
      });
      axios({
        method: "post",
        url: "https://shop2.dlqineng.com/develop/product/listPc",
        data: formData,
      })
        .then((res) => {
          this.tableData = res.data.rows;
          this.total = res.data.total;
          this.loading = false;
        })
        .catch((response) => {
          this.loading = false;
        });
    },
    getProductType() {
      axios({
        method: "post",
        url: "https://shop2.dlqineng.com/system/dict/getDictPc",
        data: { dictType: "develop_vice_product_type" },
      }).then((res) => {
        this.productTypeList = res.data;
      });
    },
    getExamineType() {
      axios({
        method: "post",
        url: "https://shop2.dlqineng.com/system/dict/getDictPc",
        data: { dictType: "develop_product_examine" },
      }).then((res) => {
        this.ExamineTypeList = res.data;
      });
    },
    handleUpdate(row) {
      axios({
        method: "post",
        url: "https://shop2.dlqineng.com/develop/product/editPcById",
        data: { id: row.id },
      }).then((res) => {
        this.title = "编辑商品";
        this.form = res.data;
        this.businessLicense =
          "https://shop2.dlqineng.com" + this.form.businessLicense;
        this.barCode = "https://shop2.dlqineng.com" + this.form.barCode;
        this.inspectionReport =
          "https://shop2.dlqineng.com" + this.form.inspectionReport;
        this.trademarkCertificate =
          "https://shop2.dlqineng.com" + this.form.trademarkCertificate;
        this.form.picture = JSON.parse(this.form.picture);
        this.galleryFileList = [];
        for (var i = 0; i < this.form.picture.length; i++) {
          this.galleryFileList.push({
            url: "https://shop2.dlqineng.com" + this.form.picture[i],
          });
        }
        // this.imageUrl = "https://shop2.dlqineng.com" + res.data.picture;
        this.addShow = true;
      });
    },
    handleDelete(row) {
      this.$confirm("确定删除该商品? 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          axios({
            method: "post",
            url: "https://shop2.dlqineng.com/develop/product/removePc",
            data: { id: row.id },
          }).then((res) => {
            this.getList();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    handleSee(row) {
      axios({
        method: "post",
        url: "https://shop2.dlqineng.com/develop/product/editPcById",
        data: { id: row.id },
      }).then((res) => {
        this.exform = res.data;
        this.exShow = true;
      });
    },
    onSubmit() {
      this.getList();
    },
    reset() {
      this.formInline = {
        pageNum: 1,
        pageSize: 10,
      };
      this.getList();
    },
    handleCreate() {
      this.form = {
        picture: [],
      };
      this.businessLicense = "";
      this.barCode = "";
      this.inspectionReport = "";
      this.trademarkCertificate = "";
      this.title = "新增商品";
      this.addShow = true;
    },
    SubmitForm() {
      this.form.businessLicense = JSON.stringify(this.businessLicenseList);
      this.form.barCode = JSON.stringify(this.barCodeList);
      this.form.inspectionReport = JSON.stringify(this.inspectionReportList);
      this.form.trademarkCertificate = JSON.stringify(
        this.trademarkCertificateList
      );
      this.form.picture = JSON.stringify(this.form.picture);
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.form.id == null) {
            this.form.createBy = this.$route.params.userId;
            axios({
              method: "post",
              url: "https://shop2.dlqineng.com/develop/product/addPc",
              data: this.form,
            }).then((res) => {
              this.addShow = false;
              this.getList();
            });
          } else {
            axios({
              method: "post",
              url: "https://shop2.dlqineng.com/develop/product/editPc",
              data: this.form,
            }).then((res) => {
              this.addShow = false;
              this.getList();
            });
          }
        }
      });
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    //修改用户状态
    updateUserStatus(row) {
      this.$confirm("确定修改用户状态? 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          axios({
            method: "post",
            url: "https://shop2.dlqineng.com/develop/product/editPc",
            data: {
              id: row.id,
              status: row.status,
            },
          }).then((res) => {
            if (res.data.code == 0) {
              this.$message({
                message: "修改成功",
                type: "success",
              });
              this.getList();
            } else {
              this.$message.error(res.data.msg);
              this.getList();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
          if (row.status == 1) {
            row.status = 0;
          } else {
            row.status = 1;
          }
        });
    },
    callbackChangeEditor(e) {
      this.form.info = e;
    },
    handleAvatarSuccess(res, file) {
      console.log("Before push:", this.form.picture);
      this.form.picture.push(file.response.fileName);
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    handleAvatarBusinessLicenseSuccess(res, file) {
      this.businessLicenseList.push(file.response.fileName);
      this.businessLicense = URL.createObjectURL(file.raw);
    },
    handleAvatarBarCodeSuccess(res, file) {
      this.barCodeList.push(file.response.fileName);
      this.barCode = URL.createObjectURL(file.raw);
    },
    handleAvatarInspectionReportSuccess(res, file) {
      this.inspectionReportList.push(file.response.fileName);
      this.inspectionReport = URL.createObjectURL(file.raw);
    },
    handleAvatarTrademarkCertificateSuccess(res, file) {
      this.trademarkCertificateList.push(file.response.fileName);
      this.trademarkCertificate = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    // 此方法用来处理上传图片
    ueditorReady: function (editorInstance) {
      //   UE.Editor.prototype._bkGetActionUrl = UE.Editor.prototype.getActionUrl;
      //   UE.Editor.prototype.getActionUrl = function(action) {
      //     if (action === 'uploadimage' || action === 'uploadscrawl' || action === 'uploadimage' || action === 'uploadfile') {
      //       return process.env.VUE_APP_BASE_API + '/storage/uploadFile';
      //     } else if (action === 'uploadvideo') {
      //       return process.env.VUE_APP_BASE_API + '/storage/uploadFile';
      //     } else {
      //       return this._bkGetActionUrl.call(this, action);
      //     }
      //   }
    },
    handleRemove: function (file, fileList) {
      for (var i = 0; i < this.form.picture.length; i++) {
        // 这里存在两种情况
        // 1. 如果所删除图片是刚刚上传的图片，那么图片地址是file.response.data.url
        //    此时的file.url虽然存在，但是是本机地址，而不是远程地址。
        // 2. 如果所删除图片是后台返回的已有图片，那么图片地址是file.url
        var urlname;
        if (file.response === undefined) {
          var url = file.url;
          let startIndex =
            url.indexOf("//shop2.dlqineng.com") +
            "//shop2.dlqineng.com".length;
          urlname = url.substring(startIndex);
        } else {
          urlname = file.response.fileName;
        }
        if (this.form.picture[i] === urlname) {
          this.form.picture.splice(i, 1);
          // this.form.picture.splice(i, 1)
        }
      }
    },
    uploadOverrun: function () {
      this.$message({
        type: "error",
        message: "上传文件个数超出限制!最多上传5张图片!",
      });
    },
    // 保存富文本内容-保存字符串
    add() {
      const params = `this.tiny?.value`;
    },
    edit() {
      // 接口回显保存html
      // this.tiny.value = res.data|| ''
    },
    handleOrder() {
      this.$router.push({ name: 'order', params: { userId: this.$route.params.userId ,token:this.$route.params.token } });
    }
  },
};
</script>
<style >
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px !important;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.edit_container .quill-editor .ql-container .ql-editor {
  height: 260px;
}
.tox-tinymce-aux {
  z-index: 999900000000 !important;
}
.tox-statusbar {
  width: 1030px !important;
}
.tox-editor-container{
  width: 1030px !important;
}
.tox-tinymce{
  width: 1030px !important;
}
</style>